<template>
   <section id="saintOuen-5" class="page-section saintOuen-5" @wheel="wheelIntersection">
      <div class="sct-left">
         <div id="suivre-ligne" class="classParent saintOuen-suivre" data-sect="sect1">
            <div class="sct-info p-0">
               <h4 class="title-h4 displayBox">{{$t('dataIntersection')['0']}}</h4>
               <figure class="mobMap-img">
                     <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuenMap-1.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuenMap-1.svg"' alt="batiment-harmony-1">
               </figure>
            </div>
   
            <div class="mob-custom w-100">
               <div class="sct-detail">
                  <p>{{$t('dataIntersection')['1']}}</p>
                  <p>{{$t('dataIntersection')['2']}}</p>
                  <p>{{$t('dataIntersection')['3']}}</p>
               </div>
            </div>

            <div class="ctn-circleBrush w-100">
               <div class="card-item" data-view="firstCircleAnim">
                  <div class="img-cercle">
                     <figure>
                        <lottie-animation class="brush-logo"
                           ref="firstCircleAnim"
                           :animationData="require('@/assets/lottie/brush-saintouen.json')"
                           :loop="false"
                           :autoPlay="false"
                           :speed="3"
                        />
                     </figure>
                  </div>
                  <div class="card-bg">
                     <figure>
                        <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' alt="cercle-brush">
                     </figure>
                  </div>

                  <ul class="ctn-parent">
                     <li v-for="firstItem in firstItems" :key="firstItem.id" class="list-item list-item-custom">
                        <div class="ctn-item">
                           <div class="ctn-item-title">
                              <span class="title">{{ firstItem.title }}</span>
                              <span class="subtitle">{{$t('dataSaintlazare')[firstItem.id-1]}}</span>
                           </div>
                           <div class="ctn-item-nbr">
                              <div>{{ firstItem.nbr }}</div>
                              <div>{{ firstItem.subNbr }}</div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>

         <!--********************************-->
         <div id="mobilite-douce" class="classParent saintOuen-mobile" data-sect="sect2">
            <div class="w-100">
               <div class="sct-info">
                  <h4 class="title-h4">{{$t('dataIntersection')['4']}}</h4>
                  <figure class="mobMap-img">
                        <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuenMap-2.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuenMap-2.svg"' alt="batiment-harmony-2">
                  </figure>
               </div>
            </div>
            <div class="txt-bloc-1">
               <div class="sct-detail">
                     <h2 class="title-h2">{{$t('dataIntersection')['5']}} <br class="d-block">{{$t('dataIntersection')['6']}}</h2>
                     <div>
                        <div class="head-txt">{{$t('dataIntersection')['7']}}</div>
                        <p>
                          {{$t('dataIntersection')['8']}}
                        </p>
                     </div>

                     <div>
                        <div class="head-txt">{{$t('dataIntersection')['9']}}</div>
                        <p>
                          {{$t('dataIntersection')['10']}}
                        </p>
                     </div>
               </div>
            </div>

            <div class="txt-bloc w-100">
               <div class="head-txt">{{$t('dataIntersection')['11']}}</div>
               <p>
                  {{$t('dataIntersection')['12']}}
               </p>
            </div>
   
            <div class="w-100 mt-90">
               <div class="card-item" data-view="secondCircleAnim">
                  <div class="img-cercle">
                     <figure>
                        <lottie-animation class="brush-logo"
                           ref="secondCircleAnim"
                           :animationData="require('@/assets/lottie/brush-saintouen.json')"
                           :loop="false"
                           :autoPlay="false"
                           :speed="3"
                        />
                     </figure>
                  </div>
                  <div class="card-bg">
                     <figure>
                        <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saint-ouen-img-mask.png"' alt="cercle-brush">
                     </figure>
                  </div>
                  <div class="ctn-parent">
                     <div class="card-title">{{$t('dataIntersection')['13']}}</div> 
                     <ul>
                        <li class="list-item" v-for="secondItem in secondItems" :key="secondItem.id">
                           <div class="ctn-item">
                              <div class="ctn-item-title">
                                 <span class="title">{{ secondItem.title }}</span>
                              </div>
                              <div class="ctn-item-nbr">{{ secondItem.nbr }}'</div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>

         <!--********************************-->
         <div id="saint-ouen-vie" class="classParent saintOuen-vie" data-sect="sect3">
            <div class="sct-info">
               <h2 class="title-h2">{{$t('dataIntersection')['14']}}<br class="d-block">{{$t('dataIntersection')['15']}}</h2>
               <figure class="mobMap-img">
                     <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuenMap-3.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuenMap-3.svg"' alt="batiment-harmony-3">
               </figure>
            </div>
            <div class="sct-detail sct-detail-custom">
               <h4 class="title-h4 title-h4-spec">{{$t('dataIntersection')['16']}}</h4>
               <ul class="list-custom">
                  <li v-for="thirdItem in thirdItems" :key="thirdItem.id"><i class="ico-circle" :class="thirdItem.color"></i>
                     {{$t('dataRestauration')[thirdItem.id - 1]}}
                  </li>
               </ul>
            </div>
         </div>        
      </div>

      <!--*****************************************************-->
      <div class="sct-right">
         <div class="listMap">
            <figure class="map-harmony" data-img="sect1">
               <img class="imgPos" :src="$t('mobiliteDouce1')" alt="batiment-harmony-1">
            </figure>
            <figure class="map-harmony" data-img="sect2">
               <img class="imgPos" :src="$t('mobiliteDouce2')" alt="batiment-harmony-2">
            </figure>
            <figure class="map-harmony" data-img="sect3">
               <img :src="$t('mobiliteDouce3')" alt="batiment-harmony-3">
            </figure>
         </div>
      </div>
   </section>
</template>

<script>
   import LottieAnimation from 'lottie-web-vue'
   export default {
      name: 'SaintOuen5',
      props: {
         msg: String,
      },
      components:{
         LottieAnimation,
      },
      data: () => ({
         observer: null,
         isMove: false,
         firstItems:[
            {id:1, title:"Saint-Lazar", subtitle:"pour relier la QCA et rayonner avec Eole!", nbr: "7'", subNbr: null},
            {id:2, title:"Madeleine", subtitle:"", nbr: "8'", subNbr: null},
            {id:3, title:"Porte Maillot", subtitle:"", nbr: "8'", subNbr: null},
            {id:4, title:"châtelet", subtitle:"", nbr: "11'", subNbr: null},
            {id:5, title:"Invalides", subtitle:"pour changer de quais!", nbr: "25'", subNbr: null},
            {id:6, title:"La Défense", subtitle:"", nbr: "28'", subNbr: null},
            {id:7, title:"Gare de Lyon", subtitle:"", nbr: "13'", subNbr: null},
            {id:8, title:"Gare du Nord", subtitle:"", nbr: "20'", subNbr: null},
            {id:9, title:"Gare Montparnasse", subtitle:"", nbr: "29'", subNbr: null},
            {id:10, title:"Roissy CDG TGV", subtitle:"", nbr: "43' > 25'", subNbr: "(2030)"},
            {id:11, title:"Aéroport d'Orly", subtitle:"", nbr: "59' > 37'", subNbr: "(2024-25)"}
         ],
         secondItems:[
            {id:1, title:"Saint-Lazar", nbr: "15'"},
            {id:2, title:"Montmartre", nbr: "20'"},
            {id:3, title:"Rue de Rivoli", nbr: "22'"},
            {id:4, title:"Canal Saint-Malin", nbr: "25'"},
            {id:5, title:"République", nbr: "26'"},
            {id:6, title:"Châtelet", nbr: "27'"}
         ],
         thirdItems:[
            {id:1, title:"Commerces à venir", color:"color-red"},
            {id:2, title:"Snacks", color:"color-lighBlue"},
            {id:3, title:"Restaurants", color:"color-orange"},
            {id:4, title:"Hypermarchés", color:"color-purple"},
            {id:5, title:"Fitness", color:"color-pink"},
            {id:6, title:"Librairie", color:"color-brown"},
            {id:7, title:"Healthy food", color:"color-green"},
            {id:8, title:"Galerie commerciales", color:"color-yellow"},
            {id:9, title:"Pharmacie", color:"color-grey"}
         ],
      }),

      methods:{
         stopBlockPropagation(e){
            e.stopPropagation();
         },
         isWheel(){
            this.isMove = true;
            setTimeout(() => {
               this.isMove = false;
            }, 1500);
         },
         wheelIntersection(e){
            e.stopPropagation();
            //** add scrollable section /
               var ParentEl = document.getElementById("saintOuen-5").parentElement
               ParentEl.classList.add("page-section-scrollable")
            //** End */
            let keyNumberBlock = document.getElementById('saintOuen-5');
            var sctBottom = Math.floor(keyNumberBlock.scrollHeight - keyNumberBlock.offsetHeight)


            if(this.isMove){
               return
            }else{
               if(e.deltaY < 0){
                  this.isWheel()
                  setTimeout(() => {
                     if(keyNumberBlock.scrollTop === 0){
                        document.querySelector('.fp-prev').click();
                     }
                  }, 500);
               }
               else{
                  this.isWheel()
                  if(Math.floor(keyNumberBlock.scrollTop) === sctBottom){
                     document.querySelector('.fp-next').click();
                  }
                  else{
                     this.stopBlockPropagation(e);
                  }
               }
            }
         },

         secondCircleAnim(){
               this.$refs.secondCircleAnim.play();
         },
      },

      mounted(){
         var parentEl = document.querySelectorAll(".sct-left .classParent");
         var listImg = document.querySelectorAll(".sct-right .map-harmony");            
         //****************************** */
         //Change sticky image
         //****************************** */
         const observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
               for(let itemImg of listImg) {
                  if(itemImg.getAttribute('data-img') === entry.target.getAttribute('data-sect')) {
                     itemImg.classList.add('openMap');
                     for(let nothis of listImg) {
                        if(nothis!==itemImg) nothis.classList.remove('openMap');
                     }
                  }
               }               
            }
         });
         for(let item of parentEl) {
            observer.observe(item);
         }

         //****************************** */
         //Circle Brush Animation
         //****************************** */
         var circleBlocParent = document.querySelectorAll(".card-item");

          const observer2 = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
               if(entry.target.getAttribute('data-view') == "firstCircleAnim"){
                  setTimeout(() => {
                     this.$refs.firstCircleAnim.play();
                  }, 200);
               }else if(entry.target.getAttribute('data-view') == "secondCircleAnim"){
                  setTimeout(() => {
                     this.$refs.secondCircleAnim.play();
                  }, 200);
               }            
            }
         });
         for(let item of circleBlocParent) {
            observer2.observe(item);
         }
      }
   };

</script>